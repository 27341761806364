.flow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-area: content;
  overflow: auto;
  height: 100%;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sidebar {
  grid-area: sidebar;
  display: grid;
  position: relative;
  height: 100%;
  z-index: 989;
}

.header {
  display: flex;
  
}

.sidebar-collapser{ 
  height: 25px;
  width: 25px;
  z-index: 999;
  background-color: #555;
  border-radius: 100%;  
  position: absolute;  
  text-align: center;
  align-content: center;
  top: calc(50vh - 15px);
  right: -12px;
}

.side-new-node {
  position: absolute;
  top: 8vh;
  height: 80vh;
  right: 0;
  z-index: 99;
}

.add-node {
  z-index: 2;
  position: absolute;
  right: 2vw;
  top: 15vh;
}

.app {
  display: flex;
  width: 100vw;
}

.container_main {
  display: grid;
  grid-template-areas: "sidebar header" "sidebar content";
  grid-auto-columns: fit-content(200px) 1fr;
  grid-template-rows: auto fit-content(calc(var(--header-height) * 1px)) 1fr;
  height: 100vh;
  width: 100vw;
}
.flow aside {
  border-left: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .flow {
    flex-direction: row;
  }

  .flow aside {
    width: 20%;
    max-width: 250px;
    height: 200px;
  }
}